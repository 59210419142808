<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="3" lg="2">
        <v-card
          :loading="loading"
          :to="{ name: 'farmers' }"
          class="text-center"
          rounded="lg"
        >
          <v-avatar tile size="120">
            <v-img
              :src="require('@/assets/icons/farmers-with-cocoa-fruit.png')"
            />
          </v-avatar>
          <v-card-title class="justify-center">
            {{ counter.farmers }} Farmer(s)
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <v-card
          :loading="loading"
          :to="{ name: 'farms' }"
          class="text-center"
          rounded="lg"
        >
          <v-avatar tile size="120">
            <v-img :src="require('@/assets/icons/102--farms.png')" />
          </v-avatar>
          <v-card-title class="justify-center">
            {{ counter.farms }} Farm(s)
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <v-card
          :loading="loading"
          :to="{ name: 'communities' }"
          class="text-center"
          rounded="lg"
        >
          <v-avatar tile size="120">
            <v-img
              :src="require('@/assets/icons/assin-south-district-square.png')"
            />
          </v-avatar>
          <v-card-title class="justify-center">
            {{ counter.communities }} Communities
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <v-card
          :loading="loading"
          :to="{ name: 'disabled-farmers' }"
          class="text-center"
          rounded="lg"
        >
          <v-avatar tile size="120">
            <v-img
              :src="require('@/assets/icons/farmers-with-cocoa-fruit.png')"
            />
          </v-avatar>
          <v-card-title class="justify-center">
            {{ counter.disabledFarmers }} Disabled Farmer(s)
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="3" lg="2">
        <v-card
          :loading="loading"
          :to="{ name: 'factories' }"
          class="text-center"
          rounded="lg"
        >
          <v-avatar tile size="120">
            <v-img
              :src="require('@/assets/icons/farmers-with-cocoa-fruit.png')"
            />
          </v-avatar>
          <v-card-title class="justify-center">
            {{ counter.factories }} Factories
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    loading: false,
    counter: {
      farmers: 0,
      farms: 0,
      disabledFarmers: 0,
      factories: 0
    },
  }),
  mounted() {
    this.loading = true;
    Promise.all([
      this.$axios.get("/farmer/count"),
      this.$axios.get("/farm/count"),
      this.$axios.get("/communities/count"),
      this.$axios.get("/farmer/disabled-count"),
      this.$axios.get("/factories/count"),
    ])
      .then((res) => {
        this.counter.farmers = res[0].data;
        this.counter.farms = res[1].data;
        this.counter.communities = res[2].data;
        this.counter.disabledFarmers = res[3].data;
        this.counter.factories = res[4].data;
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
